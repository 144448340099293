<template>
  <div>
    <div class="listwork">
      <div class="listwork__top">
        <h4>Danh sách các người lao động</h4>
      </div>
      <div class="listwork__table__01 overflow-auto">
        <vue-good-table
          class="table-w-150"
          :selectOptions="{ enabled: true }"
          :columns="columns"
          :rows="dataInvalid"
          style-class="vgt-table"
        ></vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataInvalid.length }} người lao động</p>

        <!-- {{ dataInvalid.length }} -->
      </div>
      <div class="listwork__head__02">
        <div class="listwork__head__02__left">
          <h4>Danh sách các người lao động không hợp lệ</h4>

        </div>
        <div class="listwork__head__02__right">
          <b-button
            v-b-tooltip.hover.top="'Chỉnh sửa'"
            variant="primary"
            class="btn-icon"
            @click="onOffEdit"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <div class="listwork__head__02__right__icon">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="'Kiểm tra'"
              variant="outline-primary"
              class="btn-icon"
              @click="CheckdataNoInvalid"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
          </div>
        </div>
      </div>
      <div class="listwork__table__02 overflow-auto">
        <vue-good-table
          class="table-w-150"
          :fixed-header="true"
          :columns="columns02"
          :rows="dataNoInvalid"
          :select-options="{ enabled: false}"
          style-class="vgt-table"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!--======================= Thêm icon lỗi =================================== -->
            <span
              v-if="props.column.field == 'icon02'"
              v-b-tooltip.hover.right.html="props.row.messErr[0].message"
            >
              <feather-icon
                icon="AlertTriangleIcon"
                color="red"
              />
            </span>
            <!--======================= Color cho vị trí bị lỗi =================================== -->

            <span
              v-if="props.column.field == 'fullName' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'FullName'||!props.row.fullName,dataErr:!props.row.fullName,}"
            >
              {{ props.row.fullName }}
            </span>
            <span v-if="props.column.field == 'fullName' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'fullName'"
                v-model="props.row.fullName"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.fullName, 'fullName', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'FullName'"
              >{{ props.row.fullName }}</span>
            </span>

            <!-- danh mục nghề nghiệp -->

            <div
              v-if="props.column.field == 'typeOfProfesstion' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'TypeOfProfesstion'||!props.row.typeOfProfesstion,dataErr:!props.row.typeOfProfesstion,}"
            >

              {{ props.row.typeOfProfesstion }}
            </div>
            <div
              v-if="props.column.field == 'typeOfProfesstion' && editvalueErr === true "
              class="style-table"
            >
              <v-select
                v-if="props.row.messErr[0].location === 'TypeOfProfesstion'"
                v-model="props.row.typeOfProfesstion"
                :reduce="title => title.name"
                label="name"
                :options="dataCombobox || []"
                :placeholder="'Lựa chọn danh mục nghề nghiệp'"
                @input="changeCellvalue(props.row.typeOfProfesstion, 'typeOfProfesstion', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <div
                v-if="props.row.messErr[0].location !== 'TypeOfProfesstion'"
                class="style-table"
              >{{ props.row.typeOfProfesstion }}</div>
            </div>

            <!-- Loại hợp đồng -->
            <div
              v-if="props.column.field == 'contractType' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'ContractType'||!props.row.contractType,dataErr:!props.row.contractType,}"
            >

              {{ props.row.contractType }}
            </div>
            <div
              v-if="props.column.field == 'contractType' && editvalueErr === true "
              class="style-table"
            >
              <v-select
                v-if="props.row.messErr[0].location === 'ContractType'"
                v-model="props.row.contractType"
                :reduce="title => title.name"
                label="name"
                :options="contractType"
                :placeholder="'Lựa chọn loại hình doanh nghiệp'"
                @input="changeCellvalue(props.row.contractType, 'contractType', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <div
                v-if="props.row.messErr[0].location !== 'ContractType'"
                class="style-table"
              >{{ props.row.contractType }}</div>
            </div>

            <!-- yếu tố chấn thương -->
            <div
              v-if="props.column.field == 'injuryElement' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'InjuryElement'||!props.row.injuryElement,dataErr:!props.row.injuryElement,}"
            >

              {{ props.row.injuryElement }}
            </div>
            <div
              v-if="props.column.field == 'injuryElement' && editvalueErr === true "
              class="style-table"
            >
              <v-select
                v-if="props.row.messErr[0].location === 'InjuryElement'"
                v-model="props.row.injuryElement"
                :reduce="title => title.name"
                label="name"
                :options="injuryElement"
                :placeholder="'Lựa chọn loại hình doanh nghiệp'"
                @input="changeCellvalue(props.row.injuryElement, 'injuryElement', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <div
                v-if="props.row.messErr[0].location !== 'InjuryElement'"
                class="style-table"
              >{{ props.row.injuryElement }}</div>
            </div>
            <!-- Loại chấn thương -->
            <div
              v-if="props.column.field == 'injuryReason' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'InjuryReason'||!props.row.injuryReason,dataErr:!props.row.injuryReason,}"
            >

              {{ props.row.injuryReason }}
            </div>
            <div
              v-if="props.column.field == 'injuryReason' && editvalueErr === true "
              class="style-table"
            >
              <v-select
                v-if="props.row.messErr[0].location === 'InjuryReason'"
                v-model="props.row.injuryReason"
                :reduce="title => title.name"
                label="name"
                :options="injuryReason"
                :placeholder="'Lựa chọn loại hình doanh nghiệp'"
                @input="changeCellvalue(props.row.injuryReason, 'injuryReason', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <div
                v-if="props.row.messErr[0].location !== 'InjuryReason'"
                class="style-table"
              >{{ props.row.injuryReason }}</div>
            </div>
            <!-- nguyên nhân gây tai nạn -->
            <div
              v-if="props.column.field == 'accidentReason' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'AccidentReason'||!props.row.accidentReason,dataErr:!props.row.accidentReason,}"
            >

              {{ props.row.accidentReason }}
            </div>
            <div
              v-if="props.column.field == 'accidentReason' && editvalueErr === true "
              class="style-table"
            >
              <v-select
                v-if="props.row.messErr[0].location === 'AccidentReason'"
                v-model="props.row.accidentReason"
                :reduce="title => title.name"
                label="name"
                :options="accidentReason"
                :placeholder="'Lựa chọn loại hình doanh nghiệp'"
                @input="changeCellvalue(props.row.accidentReason, 'accidentReason', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <div
                v-if="props.row.messErr[0].location !== 'AccidentReason'"
                class="style-table"
              >{{ props.row.accidentReason }}</div>
            </div>
            <!-- tình trạng gây chấn thương -->
            <div
              v-if="props.column.field == 'statusWorker' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'StatusWorker'||!props.row.statusWorker,dataErr:!props.row.statusWorker,}"
            >
              {{ props.row.statusWorker }}
            </div>
            <div
              v-if="props.column.field == 'statusWorker' && editvalueErr === true "
              class="style-table"
            >
              <v-select
                v-if="props.row.messErr[0].location === 'StatusWorker'"
                v-model="props.row.statusWorker"
                :reduce="title => title.vn"
                label="vn"
                :options="statusWorker"
                :placeholder="'Lựa chọn loại hình doanh nghiệp'"
                @input="changeCellvalue(props.row.statusWorker, 'statusWorker', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <div
                v-if="props.row.messErr[0].location !== 'StatusWorker'"
                class="style-table"
              >{{ props.row.statusWorker }}</div>
            </div>

            <!-- k bắt lỗi  -->

            <div
              v-if="props.column.field == 'socialInsuranceNumber'"
              class="style-table"
            >
              {{ props.row.socialInsuranceNumber }}
            </div>
            <div
              v-if="props.column.field == 'gender'"
              class="style-table"
            >
              {{ props.row.gender }}
            </div>
            <div
              v-if="props.column.field == 'birthDay'"
              class="style-table"
            >
              {{ props.row.birthDay }}
            </div>
            <div
              v-if="props.column.field == 'jobAge'"
              class="style-table"
            >
              {{ props.row.jobAge }}
            </div>
            <div
              v-if="props.column.field == 'salary'"
              class="style-table"
            >
              {{ props.row.salary }}
            </div>
            <div
              v-if="props.column.field == 'level'"
              class="style-table"
            >
              {{ props.row.level }}
            </div>
            <div
              v-if="props.column.field == 'levelJob'"
              class="style-table"
            >
              {{ props.row.levelJob }}
            </div>
            <div
              v-if="props.column.field == 'locationAccident'"
              class="style-table"
            >
              {{ props.row.locationAccident }}
            </div>
            <div
              v-if="props.column.field == 'workPlace'"
              class="style-table"
            >
              {{ props.row.workPlace }}
            </div>
            <div
              v-if="props.column.field == 'hour'"
              class="style-table"
            >
              {{ props.row.hour }}
            </div>
            <div
              v-if="props.column.field == 'isTraining'"
              class="style-table"
            >
              {{ props.row.isTraining }}
            </div>
            <div
              v-if="props.column.field == 'absent'"
              class="style-table"
            >
              {{ props.row.absent }}
            </div>
            <div
              v-if="props.column.field == 'healthCost'"
              class="style-table"
            >
              {{ props.row.healthCost }}
            </div>
            <div
              v-if="props.column.field == 'salaryCost'"
              class="style-table"
            >
              {{ props.row.salaryCost }}
            </div>
            <div
              v-if="props.column.field == 'indemnifyCost'"
              class="style-table"
            >
              {{ props.row.indemnifyCost }}
            </div>
            <div
              v-if="props.column.field == 'anotherCost'"
              class="style-table"
            >
              {{ props.row.anotherCost }}
            </div>
            <div
              v-if="props.column.field == 'assetCost'"
              class="style-table"
            >
              {{ props.row.assetCost }}
            </div>
            <div
              v-if="props.column.field == 'note'"
              class="style-table"
            >
              {{ props.row.note }}
            </div>

          </template>
        </vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataNoInvalid.length }} người lao động</p>
      </div>
      <div class="listwork__button">
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addClassifyHealthy()"
          >
            Thêm người lao động
          </b-button>
        </div>
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="close()"
          >
            Hủy bỏ
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import VSelect from 'vue-select'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'
import VueGoodTable from '@/components/table/VueGoodTable.vue'

export default {
  components: {
    VSelect,
    VueGoodTable,
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      dataInvalid: [],
      dataNoInvalid: [],
      columns: [
        {
          label: 'HỌ VÀ TÊN',
          field: 'fullName',
          sortable: false,
        },
        {
          label: 'Mã sổ bảo hiểm xã hội',
          field: 'socialInsuranceNumber',
          sortable: false,
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: false,
        },
        {
          label: 'Ngày sinh',
          field: 'birthDay',
          sortable: false,
        },
        {
          label: 'danh mục nghề nghiệp',
          field: 'typeOfProfesstion',
          sortable: false,
        },
        {
          label: 'tuổi nghề',
          field: 'jobAge',
          sortable: false,
        },
        {
          label: 'Mức lương',
          field: 'salary',
          sortable: false,
        },
        {
          label: 'Trình độ',
          field: 'level',
          sortable: false,
        },
        {
          label: 'Bậc thợ',
          field: 'levelJob',
          sortable: false,
        },
        {
          label: 'Loại hợp đồng lao động',
          field: 'contractType',
          sortable: false,
        },
        {
          label: 'Nơi làm việc',
          field: 'workPlace',
          sortable: false,
        },
        {
          label: 'Nơi xảy ra tai nạn lao động',
          field: 'locationAccident',
          sortable: false,
        },
        {
          label: 'Số giờ làm việc trong ca',
          field: 'hour',
          sortable: false,
        },
        {
          label: 'Yếu tố chấn thương',
          field: 'injuryElement',
          sortable: false,
        },
        {
          label: 'Loại chấn thương',
          field: 'injuryReason',
          sortable: false,
        },
        {
          label: 'Nguyên nhân gây chấn thương',
          field: 'accidentReason',
          sortable: false,
        },
        {
          label: 'Đã huấn luyện ATVSLD',
          field: 'isTraining',
          sortable: false,
        },
        {
          label: 'Tình trạng thương tích',
          field: 'statusWorker',
          sortable: false,
        },
        {
          label: 'Số ngày nghỉ phép',
          field: 'absent',
          sortable: false,
        },
        {
          label: 'Chi phí thực tế',
          field: 'healthCost',
          sortable: false,
        },
        {
          label: 'Chi phí lương trong ngày điều trị',
          field: 'salaryCost',
          sortable: false,
        },
        {
          label: 'Chi phí bồi thường',
          field: 'indemnifyCost',
          sortable: false,
        },
        {
          label: 'Chi phí khác',
          field: 'anotherCost',
          sortable: false,
        },
        {
          label: 'Thiệt hại tài sản',
          field: 'assetCost',
          sortable: false,
        },
        {
          label: 'Ghi chú',
          field: 'note',
          sortable: false,
        },
      ],
      columns02: [
        {
          label: '',
          field: 'icon02',
        },
        {
          label: 'HỌ VÀ TÊN',
          field: 'fullName',
          sortable: false,
        },
        {
          label: 'Mã sổ bảo hiểm xã hội',
          field: 'socialInsuranceNumber',
          sortable: false,
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: false,
        },
        {
          label: 'Ngày sinh',
          field: 'birthDay',
          sortable: false,
        },
        {
          label: 'danh mục nghề nghiệp',
          field: 'typeOfProfesstion',
          sortable: false,
        },
        {
          label: 'tuổi nghề',
          field: 'jobAge',
          sortable: false,
        },
        {
          label: 'Mức lương',
          field: 'salary',
          sortable: false,
        },
        {
          label: 'Trình độ',
          field: 'level',
          sortable: false,
        },
        {
          label: 'Bậc thợ',
          field: 'levelJob',
          sortable: false,
        },
        {
          label: 'Loại hợp đồng lao động',
          field: 'contractType',
          sortable: false,
        },
        {
          label: 'Nơi làm việc',
          field: 'workPlace',
          sortable: false,
        },
        {
          label: 'Nơi xảy ra tai nạn lao động',
          field: 'locationAccident',
          sortable: false,
        },
        {
          label: 'Số giờ làm việc trong ca',
          field: 'hour',
          sortable: false,
        },
        {
          label: 'Yếu tố chấn thương',
          field: 'injuryElement',
          sortable: false,
        },
        {
          label: 'Loại chấn thương',
          field: 'injuryReason',
          sortable: false,
        },
        {
          label: 'Nguyên nhân gây chấn thương',
          field: 'accidentReason',
          sortable: false,
        },
        {
          label: 'Đã huấn luyện ATVSLD',
          field: 'isTraining',
          sortable: false,
        },
        {
          label: 'Tình trạng thương tích',
          field: 'statusWorker',
          sortable: false,
        },
        {
          label: 'Số ngày nghỉ phép',
          field: 'absent',
          sortable: false,
        },
        {
          label: 'Chi phí thực tế',
          field: 'healthCost',
          sortable: false,
        },
        {
          label: 'Chi phí lương trong ngày điều trị',
          field: 'salaryCost',
          sortable: false,
        },
        {
          label: 'Chi phí bồi thường',
          field: 'indemnifyCost',
          sortable: false,
        },
        {
          label: 'Chi phí khác',
          field: 'anotherCost',
          sortable: false,
        },
        {
          label: 'Thiệt hại tài sản',
          field: 'assetCost',
          sortable: false,
        },
        {
          label: 'Ghi chú',
          field: 'note',
          sortable: false,
        },
      ],
      arry: [
        {
          en: 'Head',
          viInjusty: 'Đầu, mặt, cổ',
        },
        {
          en: 'Chest',
          viInjusty: 'Ngực, bụng',
        },
        {
          enInjusty: 'Above',
          viInjusty: 'Phần chi trên',
        },
        {
          en: 'Below',
          viInjusty: 'Phần chi dưới',
        },
        {
          en: 'Burn',
          viInjusty: 'Bỏng',
        },
        {
          en: 'Toxic',
          viInjusty: 'Nhiễm độc các chất ở mức độ nặng',
        },
      ],
      editvalueErr: false, // bật tắt chỉnh sửa
      contractType: [
        'Hợp đồng lao động vô thời hạn', 'Hợp đồng lao động xác định thời hạn', 'Hợp đồng lao động khác', 'Hợp đồng lao động dưới 2 năm',
      ],

      injuryElement: [],
      injuryReason: [],
      accidentReason: [],
      statusWorker: [
        {
          vn: 'Chết',
          en: 'Death',
        },
        {
          vn: 'Bị thương nặng',
          en: 'Serious',
        },
        {
          vn: 'Bị thương nhẹ',
          en: 'Minor',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('accidentStatistical', ['validWork', 'validNoWork', 'dataTooltip']),

    ...mapState('worker', ['dataCombobox']),
  },
  created() {
    this.dataInvalid = this.validWork
    this.dataNoInvalid = this.validNoWork
    this.checkErrorUser(this.dataTooltip)
    this.getContractType()
    this.getInjuryElement()
    this.getInjuryReason()
    this.getAccidentReason()
    this.fetchdataTypeOfProfession()
  },
  methods: {
    ...mapActions('accidentStatistical', ['getApiExcel']),
    ...mapActions('worker', ['fetchdataTypeOfProfession']),

    async getContractType() {
      const { data } = await axiosApiInstance.get(ConstantsApi.CONTRACT_TYPE, {
        params: { countryId: -1 },
      })
      this.contractType = data?.data
    },
    async getInjuryElement() {
      const { data } = await axiosApiInstance.get(ConstantsApi.INJURY_ELEMENT)
      this.injuryElement = data?.data
    },
    async getInjuryReason() {
      const { data } = await axiosApiInstance.get(ConstantsApi.INJURY_REASON)
      this.injuryReason = data?.data
    },
    async getAccidentReason() {
      const { data } = await axiosApiInstance.get(ConstantsApi.ACCIDENT_REASON)
      this.accidentReason = data?.data
    },

    // BẬT tắt chỉnh sửa
    onOffEdit() {
      this.editvalueErr = !this.editvalueErr
    },
    // thay đổi dữ liệu trên bảng
    changeCellvalue(event, field, rowData) {
      this.dataNoInvalid[rowData.originalIndex][field] = event
    },
    // nút check
    async CheckdataNoInvalid() {
      const model = {
        listExcel: this.dataNoInvalid,
        isValidate: true,
      }
      const valuedata = await this.getApiExcel(model)
      const dataInvalid01 = valuedata.data.data.filter(x => x.isSuccess === true)
      this.dataInvalid = this.dataInvalid.concat(dataInvalid01)
      const dataNoInvalid01 = valuedata.data.data.filter(x => x.isSuccess === false)
      if (dataNoInvalid01.length > 0) {
        this.dataNoInvalid = dataNoInvalid01
        this.checkErrorUser(this.dataNoInvalid)
      } else {
        this.dataNoInvalid = []
      }
    },
    // nút hủy bỏ
    close() {
      this.dataInvalid = []
      this.dataNoInvalid = []
      this.$router.go(-1)
    },
    // nút thêm
    // eslint-disable-next-line consistent-return
    async addClassifyHealthy() {
      if (this.dataInvalid.length > 0) {
        const model = {
          listExcel: this.dataInvalid,
          isValidate: false,
        }
        this.getApiExcel(model).then(res => {
          if (res.status === 200) {
            this.$root.$bvToast.toast('Thêm thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.dataInvalid = []
            if (this.dataNoInvalid.length === 0) {
              this.$router.go(-1)
            }
          } else {
            this.$root.$bvToast.toast('Thêm không thành công', {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          }
        })
      } else {
        this.$root.$bvToast.toast('Thêm không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    checkErrorUser(data) {
      // kiểm tra lỗi
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i]
          if (element.isSuccess === false) {
            /* eslint no-loop-func: "error" */
            let messageError = ''
            if (element.messErr) {
              element.messErr.forEach(err => {
                messageError += `${this.$t(`${err.message}`)} <br> `
              })
            }
            data[i].messageError = messageError
          } else { element.isSelected = true }
        }
      }
    },
  },
}
</script>

<style>
.listwork{
background: white;
box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
border-radius: 6px;

}

.listwork__top{
padding:20px;}
.listwork__top h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;

color: #181F28
}
.listwork__text__center p{
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
text-align: right;
margin-top:20px;
padding-right:20px;

}
.listwork__head__02{
display: flex;
align-items: center;
justify-content: space-between;
padding:20px;
}
.listwork__head__02__left h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #000000;
}
.listwork__head__02__right{
display: flex;
align-items: center;}
.listwork__head__02__right__icon{
margin-left: 20px;}

.listwork__button{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top:40px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.listwork__button__item{
  margin-left:20px
}
.table-listwork{
    width: 100%;
}
.table-listwork thead tr th{
  background-color: rgba(34, 127, 244, 0.12);
padding-left:10px ;
}

.table-listwork thead tr{
  height: 39px;
  background-color: transparent;
}
.table-listwork  tbody tr{
  height: 58px;
  border-bottom: 1px solid #CBCDD2;

}
.table-listwork  tbody tr th{
 padding-left:10px ;
}
.table-listwork  tbody tr td{
 padding-left:10px ;
}
.text_danger{
  color:red
}
.cssInput{
  outline: none;
  border: 0px;
  border-bottom: 0.2px solid gray;
}
.style-code123{
  padding-right: 20px ;
}
.dataErr::before{
  content: 'Không được để trống';
  color:red,
}

.table-w-150 {
  width: 200%;
}
</style>
